var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"mb-0"},[(!this.load)?_c('ShowComponent',{attrs:{"data":_vm.item,"except":[
          'id' ],"title":_vm.item.term.name},scopedSlots:_vm._u([{key:"edit",fn:function(){return [_c('div',{staticClass:"mt-0"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"width":"100px"},attrs:{"title":"Edit","to":{ name: 'edit_term_stage', id: _vm.item.id },"variant":"primary"}},[_vm._v(" Edit ")])],1)]},proxy:true},{key:"term",fn:function(ref){
        var data = ref.data;
return [_c('th',{attrs:{"scope":"row","width":'20%'}},[_vm._v(" "+_vm._s(_vm.$t("Global.term"))+" ")]),_c('td',{staticClass:"d-flex flex-wrap"},[_vm._v(" "+_vm._s(data.term.name)+" ")])]}},{key:"faculty",fn:function(ref){
        var data = ref.data;
return [_c('th',{attrs:{"scope":"row","width":'20%'}},[_vm._v(" "+_vm._s(_vm.$t("Global.faculty"))+" ")]),_c('td',{staticClass:"d-flex flex-wrap"},[_vm._v(" "+_vm._s(data.faculty.name)+" ")])]}},{key:"bylaw",fn:function(ref){
        var data = ref.data;
return [_c('th',{attrs:{"scope":"row","width":'20%'}},[_vm._v(" "+_vm._s(_vm.$t("Global.bylaw"))+" ")]),_c('td',{staticClass:"d-flex flex-wrap"},[_vm._v(" "+_vm._s(data.bylaw.name)+" ")])]}},{key:"programs",fn:function(ref){
        var data = ref.data;
return [_c('th',{attrs:{"scope":"row","width":'20%'}},[_vm._v(" "+_vm._s(_vm.$t("Global.programs"))+" ")]),_c('td',{staticClass:"d-flex flex-wrap"},[_vm._l((data.programs),function(program,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(program.name)+" "),(index != data.programs.length - 1)?_c('span',[_vm._v("  -  ")]):_vm._e()])}),(data.programs.length==0)?_c('span',[_vm._v("All Programs")]):_vm._e()],2)]}},{key:"stage_group",fn:function(ref){
        var data = ref.data;
return [_c('th',{attrs:{"scope":"row","width":'20%'}},[_vm._v(" "+_vm._s(_vm.$t("Global.stage_group"))+" ")]),_c('td',{staticClass:"d-flex flex-wrap"},[_vm._v(" "+_vm._s(data.stage_code.term_stage_group.name)+" ")])]}},{key:"stage_code",fn:function(ref){
        var data = ref.data;
return [_c('th',{attrs:{"scope":"row","width":'20%'}},[_vm._v(" "+_vm._s(_vm.$t("Global.stage_code"))+" ")]),_c('td',{staticClass:"d-flex flex-wrap"},[_vm._v(" "+_vm._s(data.stage_code.name)+" ")])]}}],null,false,3053561637)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }